<template>
  <b-card
      class="role-edit-wrapper"
  >
    <!-- form -->
    <b-form id="roleForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="名称"
              label-for="role_name"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="role_name"
                size="sm"
                v-model="role.role_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="排序"
              label-for="position"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="position"
                size="sm"
                v-model="role.position"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="ICON"
              label-for="icon"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="icon"
                size="sm"
                v-model="role.icon"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="字体颜色"
              label-for="color"
              label-size="sm"
              class="mb-1"
          >
            <v-select
                id="color"
                v-model="color"
                :options="getCodeOptions('color')"
                class="select-size-sm">
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-card
          title="功能模块"
          no-body
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
                icon="LockIcon"
                size="18"
            />
            <span class="align-middle ml-50">功能模块</span>
          </b-card-title>
        </b-card-header>
        <b-table-simple
            caption-top
            responsive
            :bordered="true"
            class="rounded-bottom mb-0"
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th>模块</b-th>
              <b-th>功能</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(top, i) in module_list" :key="top.id">
              <b-td width="15%">{{ top.module_name }}</b-td>
              <b-td width="85%">
                <b-table-simple width="100%" class="table-borderless">
                  <b-tr v-for="(module, j) in top.ext.list" :key="module.id">
                    <b-td width="20%">{{ module.module_name }}</b-td>
                    <b-td width="80%">
                      <b-form-checkbox-group
                          id="module-${module.module_id}"
                          v-model="checked"
                          name="module_id[]"
                          class="demo-inline-spacing"
                      >
                        <template v-for="sub in module.ext.list">
                          <b-form-checkbox
                              v-model="checked"
                              :value="sub.id"
                              :key="sub.id"
                          >
                            {{ sub.module_name }}
                          </b-form-checkbox>
                        </template>
                      </b-form-checkbox-group>
                    </b-td>
                  </b-tr>
                </b-table-simple>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

      </b-card>

      <b-row>
        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="primary"
              class="mr-1"
              @click="save"
          >
            保存
          </b-button>
          <b-button
              variant="outline-secondary"
              @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>

    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BTable,
  BCardHeader,
  BCardTitle,
  BCardText,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import roleStore from './roleStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, upperCase, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'

export default {
  components: {
    BMedia,
    BAvatar,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BForm,
    BFormCheckbox,
    BFormCheckboxGroup,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      role: ref({}),
      module_list: [],
      color: {},
      checked: [],
      primaryChecked: [], //原来勾选的
    }
  },
  methods: {
    //返回所勾选的三级菜单的id以及他们的父节点的id
    getAllModuleId(threeMenuArr) {
      let resultData = []
      let data = []
      for (let i = 0; i < threeMenuArr.length; i++) {
        data = this.findParent(resultData, threeMenuArr[i], this.module_list, threeMenuArr)
      }
      return data
    },
    //找出父节点的id
    findParent(parents, childNode, data, params) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id == childNode) {
          // 如果找到结果,保存当前节点
          if (!parents.includes(data[i].id)) {
            parents.push(data[i].id)
          }
          // 用当前节点再去原数据查找当前节点的父节点
          this.findParent(parents, data[i].parent_id, this.module_list, params)
          break
        } else {
          if (data[i].ext != undefined) {
            if (data[i].ext.list instanceof Array) {
              //	没找到，遍历该节点的子节点
              this.findParent(parents, childNode, data[i].ext.list, params)
            }
          }
        }
      }
      return parents
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('role')) store.registerModule('role', roleStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('role')) store.unregisterModule('role')
    })

    const edit = function () {
      store.dispatch('role/edit', {id: this.id}).then(res => {
        this.role = res.data.data

        this.color = getCode('color', this.role.color)
        this.module_list = this.role.ext.module_list
        this.checked = this.role.ext.checked_id_list
        this.primaryChecked = this.role.ext.checked_id_list
      })
    }

    const view = function () {
      this.edit()
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      this.role.color = this.color.value
      //当前选中的三级菜单
      let threeMenuArr = this.checked
      //当前所有的模块的id
      let moduleArr = this.getAllModuleId(threeMenuArr)
      // 1: 我的看板
      // 2-> 14, 15: 任务列表->代办任务,下发任务
      moduleArr.push(1, 2, 14, 15)
      //原来选中的三级菜单
      let primaryThreeMenuArr = this.primaryChecked
      //原来所有模块的id
      let primaryModuleArr = this.getAllModuleId(primaryThreeMenuArr)
      //判断是新增还是编辑
      if (this.id != 0){
        primaryModuleArr.push(1, 2, 14, 15)
      }
      let delArr = []    //需要删除的模块的id
      let addArr = []    //需要增加的模块的id
      //遍历原来模块的id 如果当前模块id数组中没有，则需要删除
      for (let i = 0; i < primaryModuleArr.length; i++) {
        if (!moduleArr.includes(primaryModuleArr[i])) {
          delArr.push(primaryModuleArr[i])
        }
      }
      //遍历当前模块的id 如果原来模块id数组中没有 则需要添加
      for (let i = 0; i < moduleArr.length; i++) {
        if (!primaryModuleArr.includes(moduleArr[i])) {
          addArr.push(moduleArr[i])
        }
      }


      this.role.delArr = delArr
      this.role.addArr = addArr

      store.dispatch('role/save', this.role).then(res => {
        toast.success('数据已保存!')
        this.$router.push({name: 'apps-role-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      upperCase,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
